<template>
  <div class="home">
    <h3>Página Inicial</h3>
  </div>
</template>

<script>
import Vue from "vue"

export default Vue.extend({
  name: "Home"
})
</script>

<style scoped>

</style>